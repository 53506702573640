import { Entity } from '@jobilla/entity';

export class AuthenticatedUserFeatures extends Entity {
  public multiFactorAuthentication: boolean = null;
  public expenses = false;
  public advancedReports = false;
  public benefits = false;
  public paystubs = false;
  public dailyPay = false;
  public perDiem = false;
  public weeklyPay = false;
  public isFlatFeeSubscriber = false;
  public timesheetEdit = true;
  public instantQueue = false;
  public instantAutoPayment = false;
}
