import { Injectable } from '@angular/core';
import { entity } from '@core/utils/rxjs';
import { ApiService } from '@services/api/api.service';
import { BehaviorSubject, Observable, shareReplay, tap } from 'rxjs';
import { User } from '@entities/users/user.entity';
import { Pager } from '@entities/pagers/pager.class';
import { QueryFilters } from '@entities/shared/query-filters.class';
import { AssignedWorker } from '@entities/assigned-workers/assigned-worker.entity';
import { UserRole } from '@entities/users/user-role.enum';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthenticatedUser } from '@entities/authentication/authenticated-user.entity';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  public currentUser$: BehaviorSubject<User> = new BehaviorSubject<User>(null);
  public constructor(private api: ApiService, private http: HttpClient) {}

  public clearCachedUser(): void {
    this.currentUser$.next(null);
  }

  public retrieveAll(pager: Pager<User>, filters?: QueryFilters): Observable<Pager<User>> {
    return this.api
      .getPagedEntities<User>({
        entityClass: User,
        path: 'users',
        filters,
        pager,
      })
      .pipe(shareReplay());
  }

  public retrieveAllApprovers(pager: Pager<User>, filters: QueryFilters = new QueryFilters()): Observable<Pager<User>> {
    filters.addNumberFilter('roleId', UserRole.Approver);
    return this.api
      .getPagedEntities<User>({
        entityClass: User,
        path: 'users',
        filters,
        pager,
      })
      .pipe(shareReplay());
  }

  public retrieveById(id: number): Observable<User> {
    return this.api.get<User>('users/' + id).pipe(
      entity<User>(User),
      tap((user) => {
        this.currentUser$.next(user);
      }),
      shareReplay()
    );
  }

  public create(user: User): Observable<User> {
    return this.api.post<User>('users', user).pipe(entity<User>(User), shareReplay());
  }
  public createFromcsv(formData: FormData) {
    const url = this.api.getUrl('users/csv');
    const headers = new HttpHeaders();
    return this.http.post<{ warnings: string[] }>(url, formData, { headers }).pipe(shareReplay());
  }
  public update(user: User): Observable<User> {
    return this.api.put<User>('users/' + user.id, user).pipe(entity<User>(User), shareReplay());
  }

  public updateTermsAndConditions(user: AuthenticatedUser): Observable<void> {
    return this.api
      .patch<void>('users/' + user.id + '/terms-and-conditions', { termsAgreed: user.termsAgreed })
      .pipe(shareReplay());
  }

  public retrieveAssignedWorkersByApprover(
    approverId: number,
    pager: Pager<AssignedWorker>,
    filters?: QueryFilters
  ): Observable<Pager<AssignedWorker>> {
    return this.api
      .getPagedEntities<AssignedWorker>({
        entityClass: AssignedWorker,
        path: 'users/' + approverId + '/assignments',
        pager,
        filters,
      })
      .pipe(shareReplay());
  }
}
