export enum UserFeature {
  MultiFactorAuthentication,
  PaymentReport,
  Benefits,
  DailyPay,
  Expenses,
  WeeklyPay,
  Paystubs,
  PerDiem,
  FlatFeeSubscription,
  TimesheetEdit,
  InstantQueue,
  InstantAutoPayment,
}
